import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("Apps");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    WEB_APP: "Apps",
    RESEARCH: "Research",
  };

  const projectsData = [
    {
      title: "Task Scheduler",
      projectInfo:
        "This project implements a task scheduler using a min-heap data structure. The min-heap allows efficient task management where the task with the highest priority is always scheduled next. The heap supports operations like inserting tasks, removing the minimum task, and heapifying a list of tasks to maintain the priority structure. The project also includes a set of unit tests to ensure the correctness of the heap operations, making it an ideal solution for managing prioritized tasks efficiently.",
      technologies: "Python, NumPy, Matplotlib",
      url: {
        name: "task-scheduler-min-heap",
        link: "https://github.com/AliMoamen/task-scheduler-min-heap",
      },
      thumbImage: "images/projects/task_scheduler.png",
      sliderImages: [],
      categories: [filters["RESEARCH"]],
    },
    {
      title: "Connect 4 AI",
      projectInfo:
        "I developed a Connect 4 game with an AI opponent that uses the Minimax algorithm with optional Alpha-Beta pruning. This Python-based project leverages Pygame for an interactive user interface, allowing players to compete against an AI with configurable difficulty levels (Easy, Medium, Hard). The AI is optimized for performance and decision-making, making it a challenging opponent even at higher difficulty settings. Additionally, I implemented a benchmarking system to track AI performance, including move selection time and nodes evaluated during the decision process.",
      technologies: "Python, Pygame",
      url: {
        name: "connect4-ai",
        link: "https://github.com/AliMoamen/connect4-ai",
      },
      thumbImage: "images/projects/connect4_ai.png",
      sliderImages: [],
      categories: [filters["RESEARCH"]],
    },

    {
      title: "Inventory Tracker AI",
      projectInfo:
        "I engineered an AI-powered Inventory Tracker application using React and TypeScript, designed to streamline and enhance inventory management. The application features secure user authentication via Firebase, supporting email and password logins to protect user data. It offers dynamic item editing and deletion, along with real-time database updates, ensuring a responsive and robust experience. Additionally, I integrated advanced AI capabilities by leveraging OpenAI Vision for item recognition and automated image generation, enabling users to efficiently manage and visually enhance their inventory items.",
      technologies: "HTML5, CSS3, React, Typescript, Sass, OpenAi Vision",
      url: {
        name: "inventory-tracker-aosman",
        link: "https://inventory-tracker-aosman.netlify.app/",
      },
      thumbImage: "images/projects/inventory_tracker.png",
      sliderImages: [],
      categories: [filters["WEB_APP"]],
    },
    {
      title: "Dictionary Web App",
      projectInfo:
        "I developed a modern dictionary application using React, Sass, and TypeScript, featuring real-time data fetching from a dictionary API. The application includes key functionalities such as word search, dark mode, audio pronunciations, and font customization to enhance user experience.",
      technologies: "HTML5, CSS3, React, Typescript, Sass",
      url: {
        name: "dictionary-app-aosman",
        link: "https://dictionary-app-aosman.netlify.app/",
      },
      thumbImage: "images/projects/dictionary_app.png",
      sliderImages: [],
      categories: [filters["WEB_APP"]],
    },
    {
      title: "Relaxify Chatbot",
      projectInfo:
        "Relaxify is an AI-powered mental health chatbot that I developed to help users manage stress and anxiety through empathetic, science-based conversations. Built with Flask and integrated with OpenAI's GPT-3.5 API, the chatbot offers a responsive and supportive environment, allowing users to engage in meaningful dialogue. I designed Relaxify with a focus on user experience, featuring a clean interface and persistent chat history for seamless interactions.",
      technologies: "HTML5, CSS3, Python, Flask",
      url: {
        name: "relaxify-production.up.railway.app",
        link: "https://relaxify-production.up.railway.app/",
      },
      thumbImage: "images/projects/relaxify_chatbot.png",
      sliderImages: [],
      categories: [filters["WEB_APP"]],
    },
    {
      title: "Beatalert",
      projectInfo:
        "I built a heart attack prediction model using the Support Vector Classifier algorithm, achieving 73% accuracy with key input features like age, gender, blood pressure, and heart rate. Additionally, I developed an intuitive user interface for easy input of health information, allowing users to access the predictive model.",
      technologies: "HTML5, CSS3, Python, Sklearn, Pandas, Flask",
      url: {
        name: "beatalert.up.railway.app",
        link: "https://beatalert.up.railway.app/",
      },
      thumbImage: "images/projects/beatalert.png",
      sliderImages: [],
      categories: [filters["WEB_APP"]],
    },
    // {
    //   title: "Simon Game",
    //   projectInfo:
    //     "I coded an interactive Simon game web application, using the DOM to capture user click and keypress events. I implemented DOM-based animations to enhance the user's visual experience and added sound effects for each button. Additionally, I engineered JavaScript code to evaluate and compare user inputs with the color sequence generated by the game.",
    //   technologies: "HTML5, CSS3, Javascript",
    //   url: {
    //     name: "aliosman-simongame.onrender.com",
    //     link: "https://aliosman-simongame.onrender.com/",
    //   },
    //   thumbImage: "images/projects/simon_game.png",
    //   sliderImages: [],
    //   categories: [filters["WEB_APP"]],
    // },
    // {
    //   title: "Shortly",
    //   projectInfo:
    //     "I created a high-performance web application for shortening URLs using the Rebrandly API, ensuring the code was testable and maintainable. The front end was designed with the Bootstrap framework for responsiveness and the EJS templating language.",
    //   technologies: "HTML5, CSS3, Javascript, NodeJs, Bootstrap, EJS",
    //   url: {
    //     name: "shortly-kto6.onrender.com",
    //     link: "https://shortly-kto6.onrender.com/",
    //   },
    //   thumbImage: "images/projects/shortly.png",
    //   sliderImages: [],
    //   categories: [filters["WEB_APP"]],
    // },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);
  const getCategories = (categories) => {
    let output = "";
    categories.forEach((category) => {
      output += output.length ? " | " + category : category;
    });
    return output;
  };

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
        style={{ padding: "5px 15px", minHeight: "750px" }}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-17  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <span
                            className="popup-ajax stretched-link project-component"
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          ></span>
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light">
                              {getCategories(project.categories)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
